import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85')
];

export const server_loads = [2,3,4,5,6,7,9,8,11,12,13];

export const dictionary = {
		"/": [~15],
		"/(app)/account/keys": [~16,[2]],
		"/(app)/account/settings": [17,[2]],
		"/(app)/analytics/claims": [~18,[2,3]],
		"/(app)/analytics/claims/claims": [19,[2,3]],
		"/(app)/analytics/claims/insights": [~20,[2,3]],
		"/(app)/analytics/claims/overview": [~21,[2,3]],
		"/(app)/analytics/gross-written-premium": [~22,[2,3]],
		"/(app)/analytics/gross-written-premium/insights": [~23,[2,3]],
		"/(app)/analytics/gross-written-premium/overview": [~24,[2,3]],
		"/(app)/analytics/gross-written-premium/policies": [25,[2,3]],
		"/(app)/analytics/loss": [~26,[2,3]],
		"/(app)/analytics/loss/claims": [27,[2,3]],
		"/(app)/analytics/loss/insights": [~28,[2,3]],
		"/(app)/analytics/loss/overview": [~29,[2,3]],
		"/(app)/analytics/rate-level": [~30,[2,3]],
		"/(app)/analytics/rate-level/insights": [~31,[2,3]],
		"/(app)/analytics/rate-level/overview": [~32,[2,3]],
		"/(app)/analytics/rate-level/policies": [33,[2,3]],
		"/(app)/analytics/renewals": [~34,[2,3]],
		"/(app)/analytics/renewals/insights": [~35,[2,3]],
		"/(app)/analytics/renewals/overview": [~36,[2,3]],
		"/(app)/analytics/renewals/policies": [37,[2,3]],
		"/(app)/analytics/retention": [~38,[2,3]],
		"/(app)/analytics/retention/insights": [~39,[2,3]],
		"/(app)/analytics/retention/overview": [~40,[2,3]],
		"/(app)/analytics/retention/policies": [41,[2,3]],
		"/auth/accept-invitation": [76,[14]],
		"/auth/accept-invitation/[code]": [77,[14]],
		"/auth/account-recovery": [~78,[14]],
		"/auth/account-recovery/[code]": [79,[14]],
		"/auth/mfa/setup": [80,[14]],
		"/auth/mfa/verify": [81,[14]],
		"/auth/signin": [~82],
		"/auth/signout": [83,[14]],
		"/auth/terms": [~84,[14]],
		"/(app)/bdx/history": [42,[2,4]],
		"/(app)/bdx/overview": [~43,[2,4]],
		"/(app)/bdx/submissions/new": [45,[2,4]],
		"/(app)/bdx/submissions/[submission_id]/status": [44,[2,4]],
		"/(app)/directory": [~46],
		"/(app)/labs": [~47,[2]],
		"/(app)/members": [~48,[2]],
		"/(app)/organizations": [~49,[2]],
		"/(app)/organizations/new": [~56,[2]],
		"/(app)/organizations/[org_id]": [~50,[2,5]],
		"/(app)/organizations/[org_id]/accelerant-team": [~51,[2,5]],
		"/(app)/organizations/[org_id]/directory-information": [52,[2,5,6]],
		"/(app)/organizations/[org_id]/directory-information/edit": [53,[2,5,6]],
		"/(app)/organizations/[org_id]/general": [54,[2,5,7]],
		"/(app)/organizations/[org_id]/general/edit": [~55,[2,5,7]],
		"/(app)/overview": [~57,[2]],
		"/(app)/planning": [~58,[2]],
		"/(app)/planning/cycles": [62,[2]],
		"/(app)/planning/cycles/new": [66,[2]],
		"/(app)/planning/cycles/[cycle_id]": [~63,[2,9]],
		"/(app)/planning/cycles/[cycle_id]/details": [64,[2,9,10]],
		"/(app)/planning/cycles/[cycle_id]/details/edit": [65,[2,9,10]],
		"/(app)/planning/[plan_id]": [~59,[2,8]],
		"/(app)/planning/[plan_id]/allocation-summary": [~60,[2,8]],
		"/(app)/planning/[plan_id]/monthly-plan": [61,[2,8]],
		"/(app)/reporting": [~67,[2,11]],
		"/(app)/reporting/new": [~70,[2,11]],
		"/(app)/reporting/[report_id]": [68,[2,11,12]],
		"/(app)/reporting/[report_id]/edit": [69,[2,11,12]],
		"/unsubscribe": [~85],
		"/(app)/users": [~71,[2]],
		"/(app)/users/invite": [~74,[2]],
		"/(app)/users/new": [~75,[2]],
		"/(app)/users/[user_id]": [72,[2,13]],
		"/(app)/users/[user_id]/edit": [73,[2,13]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';